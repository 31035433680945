import React from 'react'
import TextList from '../../../components/Article/TextList'
import { ListSmall } from '../../../components/Other/Lists'
import { TimelineList, TimelineListOpposite } from '../../../components/Other/Timeline'
import SectionContainer from '../../../components/Section/SectionContainer'
import { Card } from '@material-ui/core'
import ArticleAccordion from '../../../components/Article/ArticleAccordion'
import Statement from '../../../components/Other/Statement'
import ExerciseQuiz from '../../../components/Exercise/ExcersiseQuiz'

export default function CashFlowStatement(props) {
    
    const sectionComponents = [
        {name:'OverView',article: OverView},
        {name:'Structure',article: Structure},
        {name:'Example',article: Example},
        {name:'Exercise',article: ExerciseQuiz}
    ]

    return (
        <SectionContainer 
            sectionComponents={sectionComponents} 
            {...props}
        />
    )
}

function Example(){

    const statementContent={
        statement:'Cash Flow Statement',
        company:'Alphabet',
        date:{value:'12/30/2019',info:'Accounting period.\n3 month (Quarter) or 12 month (Year)'},
        content:[
            {header:'Operating Cash Flow'},
            {name:'Net Income',value:'34 343',info:'Company revenue minus all the costs'},
            {name:'Depreciation, Depletion, Amortization',value:'11 781',info:'Reduction in the value of an asset.'},
            {name:'Deferred Taxes',value:'173',info:'Taxes that are due to the current period but have not been paid yet'},
            {name:'Non-Cash Items',value:'7 404',info:'Expenses that does not include a cash payment like investment gains/losses and capital depreciation'},
            {name:'Cash Taxes Paid',value:'8 203',info:'Taxes that have been paid during the period'},
            {name:'Changes in Working Capital',value:'819',info:'The difference in the net working capital between accounting periods. Net working capital is calculated by current asset - current liabilities.'},
            {name:'Cash from Operating Activities', total:true,value:'54 520'},
            {header:'Investing Cash Flow'},
            {name:'Capital Expenditures',value:'-23 548',info:'Capital expenditures are the cost of a company maintaining, upgrading, or acquiring physical assets like properties, buildings, technologies or equiments.'},
            {name:'Other Investing Cash Flow Items',value:'-5 943',info:''},
            {name:'Cash from Investing Activities', total:true,value:'-29 491'},
            {header:'Financing Cash Flow'},
            {name:'Financing Cash Flow Items',value:'-4 545',info:'Dividends paid, repurchase of stock, and debt issuance.'},
            {name:'Net Issuance / Retirement of Stock',value:'-18 396',info:'Net Amount of stock issued / purchased during a period'},
            {name:'Net Issuance / Retirement of Debt',value:'-268',info:'Net Amount of debt taken  / paid back during a period'},
            {name:'Cash from Financing Activities', total:true,value:'-23 209'},
            {header:'Free Cash Flow'},
            {name:'Free Cash Flow',value:'31 157',info:'Operating Cash Flow - Capital Expenditures'},
        ]
    }

    return(
       <div className='sectionGrid2'>
            <div>
                <TextList
                    content={[
                        {header:'Alphabet Inc (Google) Cash Flow Statement',text:"Alphabet full year Cash Flow Statement from 2019"},
                    ]}
                />   
                <div className='incomeTimeLine'>
                    <h3 className='timeLineHeader'>Alphabet Inc</h3> 
                    <TimelineListOpposite list={
                        [['Founded','1998'],['Founders','Larry Page, Sergei Brin'],['Industry','Conglomerate'],['Employees','127 498']]}
                    />
                </div>
            </div>
            <div className='statementExample'>
                <Statement statement={statementContent}/>
            </div>
        </div>
    )
}

function Structure(){

    const content = [
        {header:'Operating Cash Flow',text:"Money generated by the company's normal business operations. It indicates if the company can generate enough cash to cover it's operating costs and grow. If operating cash flow is negative company may need to find temporary ways of raising capital by investing or financing which may unsustainable in the long run."},
        {header:'Investing Cash Flow',text:'Money used or generated by long term investments in specific time period. Investing cash flow includes items like purchase of a fixed assets like plant, property, equiment, sales of a division or business unit and cash from mergers and aquisitions.'},
        {header:'Financing Cash Flow',text:'Money used to fund the company. It includes items like debt, equity and dividends. Positive Investing Cash Flow may indicate that a company is not generating enough cash from operating activities and is raising new debt.'},
        {header:'Free Cash Flow',text:'Amount of money that is available for business to pay debt and dividends. Free cash Flow is calculated by: Free Cash Flow = Operating Cash Flow - Capital Expenditures. Free Cash flow can reveal problems in the company before they are seen on the income statement.'},
    ]

    const statementContent={
        statement:'Cash Flow Statement',
        company:'',
        date:{value:'Period',info:'Accounting period.\n3 month (Quarter) or 12 month (Year)'},
        content:[
            {header:'Operating Cash Flow'},
            {name:'Net Income',value:'',info:'Company revenue minus all the costs'},
            {name:'Depreciation, Depletion, Amortization',value:'',info:'Reduction in the value of an asset.'},
            {name:'Deferred Taxes',value:'',info:'Taxes that are due to the current period but have not been paid yet'},
            {name:'Non-Cash Items',value:'',info:'Expenses that does not include a cash payment like investment gains/losses and capital depreciation'},
            {name:'Cash Taxes Paid',value:'',info:'Taxes that have been paid during the period'},
            {name:'Changes in Working Capital',value:'',info:'The difference in the net working capital between accounting periods. Net working capital is calculated by current asset - current liabilities.'},
            {name:'Cash from Operating Activities', total:true,value:''},
            {header:'Investing Cash Flow'},
            {name:'Capital Expenditures',value:'',info:'Capital expenditures are the cost of a company maintaining, upgrading, or acquiring physical assets like properties, buildings, technologies or equiments.'},
            {name:'Other Investing Cash Flow Items',value:'',info:''},
            {name:'Cash from Investing Activities', total:true,value:''},
            {header:'Financing Cash Flow'},
            {name:'Financing Cash Flow Items',value:'',info:'Dividends paid, repurchase of stock, and debt issuance.'},
            {name:'Net Issuance / Retirement of Stock',value:'',info:'Net Amount of stock issued / purchased during a period'},
            {name:'Net Issuance / Retirement of Debt',value:'',info:'Net Amount of debt taken  / paid back during a period'},
            {name:'Cash from Financing Activities', total:true,value:''},
            {header:'Free Cash Flow'},
            {name:'Free Cash Flow',value:'',info:'Operating Cash Flow - Capital Expenditures'},
        ]
    }

    return(
        <div className='sectionGrid2'>
            <div> 
                <h3>Cash Flow statement Structure</h3>
                <ArticleAccordion content={content}/>                    
            </div>
            <div className='statementExample'>
                <Statement statement={statementContent}/>
            </div>
        </div>
    )
}

function OverView(){
    
    const accordion=[
        {header:'Operating',icon:'BusinessIcon'},
        {header:'Investing',icon:'ShowChartIcon'},
        {header:"Financing",icon:'AccountBalanceIcon'},
        {header:"Free Cash Flow",icon:'AttachMoneyIcon'},
    ]

    return(
        <div className='sectionGrid3'>
            <div>
                <TextList
                    content={[
                        {header:'Definition',text:"Cash Flow is the money that is flowing in or out of the business. If the net amount of cash flow is positive, company can reinvest or pay dividends to its shareholders"},
                        {header:'Components',text:`Cash Flow statement consist of 4 main components:`}
                    ]}
                /> 
                <ListSmall list={accordion}/>
            </div>
            <div className='paddingTop'>
                <TimelineList list={['Operating','Investing',"Financing","Free Cash Flow"]}/>
            </div>
            <Card className='padding'>
                <TextList
                    content={[
                        {header:'Use Cases',list:['Helps understanding if the company is generating money',"Helps understanding the company ability to debt","Provides additional information about changes in assets, liabilities and equity",'Helps at comparing different businesses by eliminating the effects of different accounting methods']},
                    ]}
                />                
            </Card>
        </div>
    )
}
