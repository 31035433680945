import React from 'react'

export default function ContactScreen() {
    return (
        <div className='privacyPolicyScreen'>
            <h1>Contact Us</h1>
            <h2>Email</h2>
            <p><a href='mailto:&#115;&#116;&#111;&#110;&#107;&#115;&#97;&#99;&#97;&#100;&#101;&#109;&#121;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;'>&#115;&#116;&#111;&#110;&#107;&#115;&#97;&#99;&#97;&#100;&#101;&#109;&#121;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;</a></p>
        </div>
    )
}
