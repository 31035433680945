export const SECTION_COMPLETE_REQUEST = 'SECTION_COMPLETE_REQUEST'
export const SECTION_COMPLETE_SUCCESS = 'SECTION_COMPLETE_SUCCESS'
export const SECTION_COMPLETE_FAIL = 'SECTION_COMPLETE_FAIL'
export const SECTION_RESET_STATUS = 'SECTION_RESET_STATUS'

export const SECTION_GET_COMPLETED_REQUEST = 'SECTION_GET_COMPLETED_REQUEST'
export const SECTION_GET_COMPLETED_SUCCESS = 'SECTION_GET_COMPLETED_SUCCESS'
export const SECTION_GET_COMPLETED_FAIL = 'SECTION_GET_COMPLETED_FAIL'

export const COURSES_RESET_REQUEST = 'COURSES_RESET_REQUEST'
export const COURSES_RESET_SUCCESS = 'COURSES_RESET_SUCCESS'
export const COURSES_RESET_FAIL = 'COURSES_RESET_FAIL'

